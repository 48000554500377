.Tasks {
	min-height: 100%;
}

.Tasks .track {
	height: 40px;
	color: white;
	display: flex;
	align-items: center;
	position: relative;
}

.Tasks .track.firstTrack {
	padding-top: 5px;
}

.Tasks .track.lastTrack {
	padding-bottom: 15px;
}

.Tasks .task {
	height: 12px;
	align-items: center;
	display: flex;
	font-size: 14px;
	user-select: none;
	overflow: hidden;
	cursor: pointer;
	padding: 9px 0px;
	/* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14); */
	filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14));
	transition-duration: 0.2s;
	z-index: 5;
}

.Tasks .task.selected:not(.future):not(.complete) {
	filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.1)) drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.15)) !important;
}

.Tasks .task.selected {
	filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.15)) drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.07)) drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.10)) !important;
}

.Tasks .task.future {
	filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.03)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.05));
	z-index: 4;
}

/* .Tasks .task.future.selected {
	filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.07)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1));
} */

.Tasks .task.complete {
	filter: none;
	z-index: 0;
}

/* .Tasks .task.complete.selected {
	filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.07)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1));
} */

.Tasks .title {
	height: 30px;
	flex-grow: 1;
	/* align-items: center;
	display: flex; */
	white-space: nowrap;
	overflow: hidden;
	/* text-overflow: ellipsis; */
	line-height: 30px;
	transition-duration: 0s !important;
	position: relative;
	letter-spacing: 0.015em;
}

.Tasks .task.complete .title {
	font-style: italic;
	font-weight: 400;
}

.Tasks .left:not(.cutoff), .Tasks .right:not(.cutoff) {
	height: 30px;
	width: 15px;
}

.Tasks .left:not(.cutoff) {
	border-radius: 5px 0 0 5px;
	cursor: ew-resize;
}

.Tasks .right:not(.cutoff) {
	border-radius: 0 5px 5px 0;
	cursor: ew-resize;
}

.Tasks .left.cutoff, .Tasks .right.cutoff {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
}

.Tasks .left.cutoff {
  /* border-right: 15px solid #C7A859; */
}

.Tasks .right.cutoff {
  /* border-left: 15px solid #C7A859; */
}

.Tasks .fader {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 20px;
}