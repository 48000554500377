:root {
	--primary: #865FAD;
	--primary-light: rgb(249, 244, 255);

	--text-primary: #553e60;
	--text-darkish: #7d598d;
	--text-medium: #9b70af;
	--text-disabled: #C1ACCB;
	--text-error: rgb(230, 125, 125);
	--text-extra-light: rgb(215, 196, 224);
	--text-small: 14px;

	--icon-primary: #606060;
	--background-grey: rgb(253, 253, 253);
	--lighter-grey: rgb(245, 245, 245);
	--light-grey: rgb(239, 239, 239);
	--grey: rgb(212, 211, 211);
	--button-selected: rgb(248, 233, 253);

	--border-color: #EDEDED;
	--calendar-border: 2px solid var(--border-color);
	--calendar-border-dark: 2px solid rgb(199, 199, 199);

	--regular: 400;
	--medium: 500;
	--bold: 700;
}

.medium {
	font-weight: var(--medium);
}

.bold {
	font-weight: var(--bold);
}

.small {
	font-size: var(--text-small);
}

.textfield {
	border-bottom: thin solid var(--light-grey);
	transition-duration: 0.2s;
	font-size: var(--text-small);
	line-height: 1.2;
	outline: none;
	cursor: text;
}

.textfield:focus {
	border-bottom: thin solid rgb(165, 143, 187);
}

.input-field {
	border: none;
	border-bottom: thin solid var(--light-grey);
	transition-duration: 0.2s;
	font-size: var(--text-small);
	line-height: 1.2;
	outline: none;
	cursor: text;
	color: var(--text-primary);
	font-family: 'Ubuntu';
}

.input-field:focus {
	border-bottom: thin solid rgb(165, 143, 187);
}

.input-field::placeholder {
	color: var(--text-disabled);
	font-family: 'Ubuntu';
}

.button {
	cursor: pointer;
	user-select: none;
}

.button.selected {
	background-color: var(--button-selected);
}

.button.disabled, .textbutton.disabled {
	cursor: default;
	color: var(--text-disabled);
	pointer-events: none;
}

.button:hover, .textbutton:hover {
	background-color: var(--lighter-grey);
}

.button:active, .textbutton:active {
	background-color: var(--light-grey);
}

.invisible {
	color: transparent;
}

.textbutton {
	user-select: none;
	padding: 13px 18px;
	cursor: pointer;
	letter-spacing: 0.015em;
	border-radius: 4px;
	transition-duration: 0.1s;
}

.textbutton.default, .button.default {
	background-color: rgb(245, 238, 255);
}

.textbutton.default:hover, .button.default:hover {
	background-color: rgb(239, 227, 253);
}

.textbutton.default:active, .button.default:active {
	background-color: rgb(227, 205, 252);
}

