#Streams {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;
	height: calc(100vh - 60px - 45px);
}

.Stream {
	/* min-height: 50px; */
	display: flex;
	transition-duration: 0.2s;
}

.Stream.selected {
	background-color: rgba(132, 0, 255, 0.075);
}

.stream.title {
	flex: 0 0 250px;
	width: 250px;
	display: flex;
	/* align-items: center; */
	font-size: 15px;
	user-select: none;
}

.stream.icon {
	margin-left: 5px;
	margin-right: 3px;
}
.stream.icon-collapse {
	opacity: 0;
}

.stream.text {
	margin-left: 40px;
	margin-right: 20px;
	/* min-height: 35px;
	max-height: 50px; */
	height: 30px;
	overflow: hidden;
	text-overflow: clip;
	overflow-wrap: break-word;
	padding: 10px 0;
	display: flex;
	align-items: center;
}

.stream.text.unnamed {
	color: var(--text-extra-light)
}

.stream.title:hover .stream.icon-collapse {
	opacity: 1;
}

.stream.task-container {
	flex-grow: 1;
	position: relative;
}

#Streams .stream-spacer {
	flex-grow: 1;
}

.stream.title.new-stream {
	color: var(--text-disabled) !important;
	transition-duration: 0.2s;
	border-radius: 4px;
}

.stream.title.new-stream:hover {
	color: var(--text-medium) !important;
	background-color: var(--lighter-grey);
	cursor: pointer;
}

.stream.title.new-stream:active {
	background-color: var(--light-grey);
}

.stream.title.new-stream .text {
	margin-left: 25px;
}

.stream.title.new-stream .material-icons.new-icon {
	color: var(--text-disabled) !important;
	pointer-events: none;
	opacity: 1 !important;
}

.stream.title.new-stream:hover .material-icons.new-icon {
	color: var(--text-medium) !important;
}
