.Menu {
	display: none;
	position: absolute;
	right: -5px;
	padding: 5px 5px;
	transition-duration: 0.15s;
	z-index: 100;
}

.menu.content {
	background-color: white;
	border-radius: 5px;
	min-width: 150px;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
	padding-top: 5px;
	padding-bottom: 10px;
}

.menu.container {
	position: relative;
	display: inline-block;
}

.menu.margin {
	padding: 20px;
}

.menu.container.hover:hover .Menu, .menu.margin:hover .Menu, .menu.container.show .Menu, .Menu.show {
	display: block;
}

.menu.option {
	font-size: 14px;
	height: 40px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.menu.option:hover {
	background-color: var(--light-grey);
}

.menu.option.disabled {
	color: var(--text-disabled);
	cursor: auto;
}

.menu.option.disabled:hover {
	background-color: white;
}