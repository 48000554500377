.datetime-picker {
	padding: 10px 15px;
	padding-bottom: 0;
	display: grid;
	grid-template-areas:
		"l m m m m m r"
		"auto auto auto auto auto auto auto";
	grid-auto-columns: 1fr;
	user-select: none;
	font-size: 15px;
}

.datetime-picker > * {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.datetime-picker .arrow {
	font-size: 20px;
	height: 25px;
	width: 25px;
}

.datetime-picker .arrow.left {
	grid-area: l;
}

.datetime-picker .arrow.right {
	grid-area: r;
}

.datetime-picker .month {
	grid-area: m;
}

.datetime-picker .weekday {
	padding: 4px;
}

.datetime-picker .day {
	color: var(--text-primary);
	padding: 7px;
	border-radius: 100px;
}

.datetime-picker .day.othermonth {
	color: var(--text-medium);
}

.datetime-picker .day.disabled {
	pointer-events: none;
	color: var(--text-disabled);
}