#App {
  display: flex;
  flex-direction: column;
}

#Content {
  flex-grow: 1;
  display: flex;
}

#Main {
  flex-grow: 1;
  position: relative;
}

#Overlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#Calendar {
  width: calc(100% - 250px);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -10;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

*:hover>::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.03);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}