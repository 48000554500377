.Popup {
	/* display: none; */
	position: absolute;
	padding: 5px;
	transition-duration: 0.15s;
	z-index: 1000;
}

.Popup.top {
	top: 30px;
}

.Popup.right {
	right: 30px;
}

.Popup.bottom {
	bottom: 30px;
}

.Popup.left {
	left: 30px;
}

.popup.content {
	background-color: white;
	border-radius: 5px;
	min-width: 300px;
	max-width: 450px;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
	padding: 30px 35px;
}

.popup.title {
	font-weight: var(--bold);
	font-size: 25px;
	margin-bottom: 20px;
}

.popup.text {
	line-height: 1.4;
	white-space: pre-wrap;
	letter-spacing: 0.01em;
	font-weight: var(--regular);
	font-family: 'Lato';
}

.popup.container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup.container.dark {
	background-color: #e1c5fd31;
	z-index: 50;
}

.popup.margin {
	padding: 20px;
}

.popup.container.hover:hover .Popup, .popup.margin:hover .Popup, .popup.container.show .Popup, .Popup.show {
	display: block;
}

.popup.actions {
	display: flex;
	justify-content: space-around;
	margin-top: 30px;
}