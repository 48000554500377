.Calendar {
	width: 100%;
	height: 100%;
	display: flex;
	user-select: none;
}

.calendar.day {
	flex-grow: 1;
	border-left: var(--calendar-border);
	text-align: center;
	display: flex;
	justify-content: center;
	white-space: nowrap;
	overflow: hidden;
}

.calendar.day.first {
	border: none !important;
}

.calendar.day.current {
	background-color: var(--primary-light);
}

.calendar.day.current .calendar.label {
	font-weight: var(--bold) !important;
}

.calendar.day.past {
	background-color: var(--background-grey);
}

.calendar.day.past .calendar.label {
	color: var(--text-disabled);
}

.calendar.day.start-of-week {
	border-left: var(--calendar-border-dark);
}

.calendar.label {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	max-width: 0;
}