#Sidebar {
	flex: 0 0 300px;
	max-width: 300px;
	display: flex;
	flex-direction: column;
	border-left: var(--calendar-border);
}

.sidebar.title {
	flex: 0 0 30px;
	display: flex;
}

.sidebar.title-text {
	height: 30px;
	flex: 1 0 0;
	justify-content: center;
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 20px;
}

.sidebar.title-icon {
	margin: 0 20px;
}

.sidebar.content {
	flex-grow: 1;
	margin: 20px 30px;
}

#Sidebar .actions {
	height: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 25px;
	font-size: 15px;
}

#Sidebar .actions .action {
	user-select: none;
	padding: 10px 15px;
	cursor: pointer;
	letter-spacing: 0.015em;
}

#Sidebar .actions .action.disabled {
	pointer-events: none;
	color: var(--text-disabled);
}

#Sidebar .actions .action:hover {
	background-color: var(--lighter-grey);
}

#Sidebar .actions .action:active {
	background-color: var(--light-grey);
}

#Sidebar .actions .spacer {
	flex-grow: 1;
}

.basicOptions {
	height: 160px;
	overflow-y: auto;
}

.basicOptions::-webkit-scrollbar {
  width: 6px;
}