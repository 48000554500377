#Header {
  flex: 0 0 60px;
  display: flex;
}

#Logo-container {
	margin-left: 50px;
  margin-right: 60px;
  display: flex;
}

#Logo {
  justify-content: center;
  flex-direction: column;
  display: flex;
  font-family: 'Ubuntu', sans-serif;
  user-select: none;
  font-size: 25px;
  color: var(--primary);
}

#Badge {
  display: flex;
  align-items: flex-end;
  color: #a989c9;
  font-family: 'Ubuntu', sans-serif;
  font-size: 12px;
  padding-bottom: 7px;
  user-select: none;
}

#header-left {
	flex: 1 0 0;
  align-items: center;
  display: flex;
}

#header-left > * {
  margin-right: 15px;
}

#header-right > * {
  margin-left: 15px;
}

#Title {
  /* font-family: 'Ubuntu', sans-serif; */
  width: 240px;
  text-align: center;
  font-weight: var(--medium);
  font-size: 25px;
  color: var(--text-primary);
  user-select: none;
}

#header-right {
  margin: 0 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

