#SignIn {
  display: flex;
  flex-direction: column;
}

.page-header {
	text-align: center;
	margin-top: 17vh;
	font-weight: bold;
	font-size: 30px;
	margin-bottom: 50px;
}

#SignIn .textfield-container {
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#SignIn .textfield, #SignIn .input-field {
	width: 250px;
	font-size: 18px;
	padding: 5px 10px;
}

#SignIn .input-field::placeholder {
	font-size: 18px;
}

#SignIn .bottom-text {
	margin-top: 60px;
	user-select: none;
	color: var(--text-medium);
	flex-direction: column;
}