.sidebarParts.formgroup {
	margin-bottom: 20px;
}

.sidebarParts.heading {
	margin-bottom: 5px;
}

.sidebarParts.textinfo {
	min-height: 18px;
	display: flex;
	align-items: center;
	user-select: none;
	padding: 11px 15px;
}

.sidebarParts.buttonfield {
	min-height: 18px;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	padding: 11px 15px;
	border-radius: 4px;
	transition-duration: 0.2s;
}

.sidebarParts.buttonfield:hover {
	background-color: var(--lighter-grey);
}

.sidebarParts.buttonfield:active {
	background-color: var(--light-grey);
}

.sidebarParts.buttonfield.selected {
	background: var(--button-selected);
	/* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05); */
}

.sidebarParts.buttonfield .color {
	width: 18px;
	height: 18px;
	margin-right: 15px;
	border-radius: 3px;
}

.sidebarParts.description {
	width: 100%;
	cursor: text;
	min-height: 20px;
	user-select: none;
	padding: 10px 5px;
	transition-duration: 0.2s;
	white-space: pre-wrap;
	word-wrap: break-word;
	line-height: 1.2;
}

.sidebarParts.description:hover {
	background-color: var(--lighter-grey);
}

.sidebarParts.description.placeholder {
	color: var(--text-disabled);
}

.sidebarParts.editDescription {
	background-color: var(--lighter-grey);
	width: 100%;
	cursor: text;
	padding: 10px 5px;
	white-space: pre-wrap;
	word-wrap: break-word;
	resize: vertical;
	border: none;
	border-bottom: thin solid rgb(165, 143, 187);
	font-size: var(--text-small);
	line-height: 1.2;
	outline: none;
	font-family: 'Ubuntu';
	font-size: var(--text-small);
}

.sidebarParts.editDescription::placeholder {
	color: var(--text-disabled);
}

.sidebarParts.title {
	flex: 1 0 0;
	text-align: center;
	outline: none;
	font-size: 20px;
	font-weight: bold;
	padding: 5px 0;
	word-break: break-word;
	cursor: text;
}

[contentEditable=true]:empty:before{
	content: attr(placeholder);
	color: var(--text-disabled);
}

.sidebarParts .value {
	white-space: nowrap;
	overflow: hidden;
}

.sidebarParts.numberpicker {
	min-height: 40px;
}

.sidebarParts.numberpicker .picker {
	display: flex;
}

.sidebarParts.numberpicker .picker .number {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	text-align: center;
	word-break: break-all;
}

.sidebarParts.numberpicker .picker .button {
	border-radius: 3px;
	width: 10px;
	display: flex;
	justify-content: center;
}

.sidebarParts.numberpicker .picker .unit {
	user-select: none;
	width: 70px;
}

.sidebarParts.numberpicker .picker > * {
	padding: 11px 15px;
	display: flex;
	align-items: center;
}

.sidebarParts.numberpicker .error {
	color: var(--text-error);
	font-size: 14px;
	padding-top: 10px
}

.sidebarParts.actions {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	justify-content: center !important;
}

.sidebarParts.timepicker {
	min-height: 40px;
}

.sidebarParts.timepicker .picker {
	display: flex;
}

.sidebarParts.timepicker .picker .number {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	text-align: center;
	word-break: break-all;
}

.sidebarParts.timepicker .picker > * {
	padding: 11px 15px;
	display: flex;
	align-items: center;
}

.sidebarParts.timepicker .error {
	color: var(--text-error);
	font-size: 14px;
	padding-top: 10px
}
