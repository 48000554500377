.material-icons {
	height: 35px;
	width: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	color: var(--icon-primary);
	user-select: none;
	transition-duration: 0.15s;
}

.material-icons.clickable {
	cursor: pointer;
}

.material-icons.clickable:hover {
	background-color: var(--light-grey);
}

.material-icons.clickable:active, .material-icons.hover, .material-icons.hover:hover, .material-icons.clickable.hover:hover {
	background-color: var(--grey);
}
